import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Cookies from '../components/Cookies';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/UI/Content';
import ContentContainer from '../components/UI/ContentContainer';

const Title = styled.h1`
  padding-left: 2.75rem;
  padding-right: 2.75rem;
  margin-top: 4rem;
  margin-bottom: 2rem;
`;
const ParagraphContainer = styled.div`
  padding-left: 2.75rem;
  padding-right: 2.75rem;
`;

export const AvisospageTemplate = ({
  title,
  content,
  contentComponent,
  information,
  interestRate,
}) => {
  const AvisosContent = contentComponent || Content;

  return (
    <section>
      <Cookies/>
      <ContentContainer>
        <Title>{title}</Title>
        <ParagraphContainer>
          {information.map(item => (
            <p>
              {item.text}
              {item.text ===
                'Conoce las recomendaciones de la Superintendencia Financiera de Colombia' && (
                <>
                  <a href="https://www.superfinanciera.gov.co/inicio/advertencias-por-suplantacion--10097510">
                    aquí
                  </a>
                  .
                </>
              )}
            </p>
          ))}
        </ParagraphContainer>
        <AvisosContent className="content" content={content} />
      </ContentContainer>
    </section>
  );
};

AvisospageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  information: PropTypes.shape({
    text: PropTypes.string,
  }),
  content: PropTypes.string,
  interestRate: PropTypes.string,

  contentComponent: PropTypes.func,
};

const AvisosPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AvisospageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        information={post.frontmatter.information}
        content={post.html}
        interestRate={post.frontmatter.interestRate}
      />
    </Layout>
  );
};

AvisosPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default AvisosPage;

export const AvisosPageQuery = graphql`
  query AvisosPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        information {
          text
        }
        interestRate
      }
    }
  }
`;
